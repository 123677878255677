import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import AnimationRevealSection from "helpers/AnimationRevealSection";
import WhyChooseUs from "components/features/TwoColWithTwoHorizontalFeaturesAndButton";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import HowWeDoIt from "components/features/TwoColWithSteps";
import Payless from "components/features/TwoColWithButtonWithPrimaryBackground";
import Analytics from "components/features/TwoColWithButton";

import { ReactComponent as ActivityIcon } from "feather-icons/dist/icons/activity.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as AwardIcon } from "feather-icons/dist/icons/award.svg";
import PaylessImage from "images/undraw_discount_d4bd.svg";
import AnalyticsImage from "images/undraw_data_trends_b0wg.svg";
import HowWeDoItImage from "images/undraw_shared_goals_3d12.svg";
import WhyChooseUsImage from "images/undraw_dashboard_nklg.svg";
const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => {
  const HighlightedText = tw.span`text-red-500`;
  const SmallText = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 text-gray-100`;
  return (
    <StyledDiv className="App">
      <Header roundedHeaderButton={true} />
      <AnimationRevealSection>
        <WhyChooseUs
          imageSrc={WhyChooseUsImage}
          heading={
            <>
              WHY <HighlightedText>CHOOSE US</HighlightedText>?
            </>
          }
          hideButton={true}
          description={
            <>
              If you are looking to hire right talent
              <br /> Search No more...
            </>
          }
          features={[
            {
              Icon: AwardIcon,
              title: "Recruitment",
              description:
                "Outsource Recruitment and save time, effort and money during recruitment cycle.",
              iconContainerCss: tw`bg-teal-300 text-teal-800`,
            },
            {
              Icon: MoneyIcon,
              title: "Pay Less",
              description:
                "Pay less for trained staff. Hire fresh grads who are productive from day one.",
              iconContainerCss: tw`bg-indigo-300 text-indigo-800`,
            },
            {
              Icon: ActivityIcon,
              title: "Analytics",
              description:
                "Use our analytical engine to better assess the candidates.",
              iconContainerCss: tw`bg-red-300 text-red-800`,
            },
          ]}
        />
        {/* <Payless
          subheading={""}
          heading={"Pay Less For Trained Staff"}
          description={"Hire fresh grads who are productive from day one."}
          imageSrc={PaylessImage}
          buttonHidden={true}
        />
        <Analytics
          subheading={""}
          heading={"Analytics"}
          description={
            "Use our analytical engine to better assess the candidates."
          }
          imageSrc={AnalyticsImage}
          buttonHidden={true}
        /> */}
        <GetStarted
          isExternal={false}
          primaryLinkText={"Contact Us"}
          primaryLinkUrl={"/contact-us"}
          secondaryLinkUrl={"/contact-us"}
          hideSecondaryButton={true}
          text={
            <>
              {"Need help in your current project?"} <br />{" "}
              <SmallText>
                Get in touch for expert-vetted part time and contract based hire
              </SmallText>
            </>
          }
        />
        <HowWeDoIt
          imageSrc={HowWeDoItImage}
          heading={"Who is the right talent?"}
          subheading={""}
          steps={[
            {
              heading: "Right Attitude",
              description: "",
            },
            {
              heading: "Relevant Skills",
              description: "",
            },
            {
              heading: "Effective Communication",
              description: "",
            },
            {
              heading: "Relevant Experience",
              description: "",
            },
          ]}
        />
      </AnimationRevealSection>
      <Footer />
    </StyledDiv>
  );
};
