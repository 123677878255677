import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";
const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const HighlightedText = tw.span`text-red-500`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <StyledDiv>
      <Header />
      <AnimationRevealPage>
        <MainFeature1
          subheading={<Subheading>About First Flight</Subheading>}
          heading="Who We are & What we do?"
          buttonRounded={false}
          primaryButtonText="Contact Us"
          primaryButtonUrl="/contact-us"
          description={
            <>
              First Flight is a training and recruitment company that aims to
              move the right talent to right place. Our approach is consultative
              and our core business is recruiting fresh graduates for Tech
              Industry.<br/>
               We understand the value of building partnerships with
              our clients, that’s why you’ll be given a dedicated consultant
              with experience in the industry, who’ll look after your needs
              throughout the recruitment process. <br/> 
              We pre-screen all candidates
              before onboarding in our training program. Selected candidates go
              through a rigorous process of training, evaluation(test,
              interview) and practical implementation according to market
              standards.<br/> 
              We take the time to listen to our clients so that we
              can find right talent to fill in your vacancy. This is one reason
              why our candidate to vacancy matching process is so successful.
              <br/>
              <HighlightedText>But don’t take our word for it – give us a try!</HighlightedText>
            </>
          }
          imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
          buttonHidden={false}
        />
        <MainFeature1
          subheading={<Subheading>Our Vision</Subheading>}
          heading="To be recognized as leaders in the provision of quality training and recruitment services."
          description=""
          buttonRounded={false}
          primaryButtonText="Contact Us"
          imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
          textOnLeft={false}
          buttonHidden={true}
        />
        <MainFeature1
          subheading={<Subheading>Mission</Subheading>}
          heading="Our Mission"
          description="At First Flight, We are dedicated to providing equal opportunities to fresh graduates to reach their academic potential while preparing them for multiple roles in the software industry.
        In training our students, We strive to meet their technical, intellectual and emotional needs and equip them with skills, knowledge and experience to meet the demands of the job market."
          buttonRounded={false}
          primaryButtonText="Contact Us"
          imageSrc="https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
          buttonHidden={true}
        />
        <Footer />
      </AnimationRevealPage>
    </StyledDiv>
  );
};
