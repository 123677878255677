import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import AnimationRevealSection from "helpers/AnimationRevealSection.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButtonWithPrimaryBackground.js";
import FAQ from "components/faqs/SingleCol";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import ServerIcon from "images/server-icon.svg";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-red-500`;
  const SmallText = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 text-gray-100`;
  return (
    <StyledDiv className="App">
      <Header roundedHeaderButton={true} />
      <AnimationRevealSection>
        <Hero roundedHeaderButton={true} />
        <MainFeature2
          subheading={<Subheading></Subheading>}
          heading={
            <>
              JOIN OUR <HighlightedText>TEAM</HighlightedText> TODAY!
            </>
          }
          description={
            <>
              Train from the best minds in the business.
              <br />
              <HighlightedText>FIND YOUR DREAM JOB!</HighlightedText>
            </>
          }
          isExternal={false}
          primaryButtonUrl={"/training"}
          imageSrc={prototypeIllustrationImageSrc}
          showDecoratorBlob={false}
          features={[
            {
              Icon: MoneyIcon,
              title: "Affordable",
              description:
                "Train Remotely And Pay No Tuition Until You’re Hired.            ",
              iconContainerCss: tw`bg-green-300 text-green-800`,
            },
            {
              Icon: BriefcaseIcon,
              title: "Professionalism",
              description: "Trust Us For Diverse Tech Talent.            ",
              iconContainerCss: tw`bg-red-300 text-red-800`,
            },
          ]}
        />
        <Features
          subheading={<Subheading></Subheading>}
          description={"We believe in the best!"}
          cards={[
            {
              imageSrc: ServerIcon,
              title: "Software Training",
              description: "Training talent according to industry standards.",
              link: "/training"
            },
            {
              imageSrc: ReliableIconImage,
              title: "Tech Recruitment",
              description: "Find trained resources.",
              link: "/recruitment"
            },
          ]}
          heading={
            <>
              Our <HighlightedText>Services.</HighlightedText>
            </>
          }
        />
        <GetStarted
          isExternal={false}
          primaryLinkUrl={"/recruitment"}
          secondaryLinkUrl={"/contact-us"}
          text={
            <>
              {"Curious how First Flight works for large organizations?"} <br />{" "}
              <SmallText>
                Get in contact for experience tailored to your needs.
              </SmallText>
            </>
          }
        />
        <FAQ
          imageSrc={customerSupportIllustrationSrc}
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              You have <HighlightedText>Questions ?</HighlightedText>
            </>
          }
          description={
            "Whether you’re attending an upcoming training or looking for information before you buy, here are some of the most commonly asked questions we’ve received. If you can’t find an answer to your question, just reach out to us for an answer and recommendations."
          }
          faqs={[
            {
              question: "What is first flight? ",
              answer:
                "First Flight is training and recruitment company that aims to train fresh graduates and help them to find their dream job. Candidates go through a rigorous process of training, evaluation(Test, Interview) and practical implementation according to market standards. ",
            },
            {
              question: "What is the duration of the training?",
              answer:
                "The training completes in 8 weeks which includes training, evaluations(Test, Interview) and Real world project implementation.",
            },
            {
              question: "Will you give us guidelines about interview process?",
              answer:
                "Yes, before presenting to the companies, you will undergo a set of interviews which will give you confidence and real world experience.",
            },
            {
              question: "Is it prepaid or I can apply for loan?",
              answer:
                "We are offering both prepaid training and a loan for deserving candidates.",
            },
            {
              question: "Is the course theoretical or practical?",
              answer:
                "Our training is a combination of concepts and their practical implementation. We also provide live exposure on projects and give assignments to test your skill sets.",
            },
            {
              question: "Do you provide Live Project with Training?",
              answer:
                "Yes, after the completion of the training,We will provide opportunity to work on our live projects.",
            },
            {
              question:
                "What are the qualifications required to join the training program?",
              answer: (
                <>
                  - Basic Programming concepts.
                  <br />
                  - Ability to communicate effectively in English.
                  <br />- Must have a can do attitude.
                </>
              ),
            },
            {
              question: "Does your institute provide 100% Placement Guarantee?",
              answer:
                "Yes, we do provide 100% Placement Guarantee. Terms & Conditions Applied. Please review the terms & Conditions for details.",
            },
            {
              question: "Do you provide placement assistance?",
              answer: (
                <>
                  Yes, we are one of the trusted institutes helping with genuine
                  placement assistance.
                  <br />
                  We will present you to multiple companies where you can secure
                  a job based on your performance.
                </>
              ),
            },
            {
              question: "Can I work as freelancer?",
              answer:
                "Yes, Our training program is designed to help you learn every concept that you can use to implement production ready projects. At the end of the course we will discuss various concepts of Freelancing to help you get started in this industry.",
            },
            {
              question: "Who will clarify our doubts ?",
              answer:
                "You can ask any questions to our faculty/trainer by whatsapp/email. He will respond to all your queries.",
            },
            {
              question: "What if I miss my lesson?",
              answer:
                "Every training session is recorded so that candidates can go through latter.",
            },
          ]}
        />

        <Footer />
      </AnimationRevealSection>
    </StyledDiv>
  );
};
