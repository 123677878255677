import React from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import { Container, ContentWithPaddingXl } from "components/misc/Layouts"
import tw from "twin.macro"
import styled from "styled-components"
import Header from "components/headers/light.js"
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading } from "components/misc/Headings"

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-gray-900 mb-10`
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <StyledDiv>
      <Header />
      <AnimationRevealPage>
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <Text>
              <p>Last updated: December 2, 2020</p>

              <p>
                This privacy policy has been compiled to better serve those who are concerned with how their 'Personally
                Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information
                security, is information that can be used on its own or with other information to identify, contact, or
                locate a single person, or to identify an individual in context. Please read our privacy policy carefully
                to get a clear understanding of how we collect, use, protect or otherwise handle your Personally
                Identifiable Information in accordance with our website.
              </p>

              <p>
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this Privacy Policy.
              </p>

              <h1>Collecting and Using Your Personal Data</h1>

              <h3>What personal information do we collect from the people that visit our blog, website or app?</h3>
              <p>
                When ordering or registering on our site, as appropriate, you may be asked to enter your name, email
                address, mailing address, phone number or other details to help you with your experience.
              </p>

              <h3>When do we collect information?</h3>
              <p>We collect information from you when you fill out a form or enter information on our site.</p>
              <h3>Why do we collect information?</h3>
              <p>
                We may use the information we collect from you when you register, make a purchase, sign up for our
                newsletter, respond to a survey or marketing communication, surf the website, or use certain other site
                features in the following ways:
              </p>
              <ul>
                <li>
                  <p>
                    To allow us to better service you in responding to your customer service requests and should you join
                    as a student, in providing you with our services.</p>
                </li>
                <li>
                  <p>To administer a contest, promotion, survey or other site feature.</p>
                </li>
                <li>
                  <p>To quickly process your transactions.</p>
                </li>
                <li>
                  <p>
                    To ask for ratings and reviews of services or products.
                  </p>
                </li>
                <li>
                  <p>To follow up with them after correspondence (live chat, email or phone inquiries).</p>
                </li>
              </ul>
              <p>
                Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make
                your visit to our site as safe as possible.
              </p>
              <p>
                Your personal information is contained behind secured networks and is only accessible by a limited number
                of persons who have special access rights to such systems, and are required to keep the information
                confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket
                Layer (SSL) technology.
              </p>
              <p>
                We implement a variety of security measures when a user enters, submits, or accesses their information to
                maintain the safety of your personal information.
              </p>
              <p>
                All transactions are processed through a gateway provider and are not stored or processed on our servers.
              </p>
              <h3>Do we use 'cookies'?</h3>
              <p>
                Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive
                through your Web browser (if you allow) that enables the site's or service provider's systems to recognize
                your browser and capture and remember certain information. For instance, we use cookies to help us
                remember and process the items in your shopping cart. They are also used to help us understand your
                preferences based on previous or current site activity, which enables us to provide you with improved
                services. We also use cookies to help us compile aggregate data about site traffic and site interaction so
                that we can offer better site experiences and tools in the future.
              </p>
              <p>
                We use cookies to:
              </p>
              <ul>
                <li>
                  <p>Keep track of advertisements</p>
                </li>
              </ul>
              <p>
                You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn
                off all cookies. You do this through your browser settings. Since every browser is a little different,
                look at your browser's Help Menu to learn the correct way to modify your cookies.If you turn cookies off,
                Some of the features that make your site experience more efficient may not function properly. It won't
                affect the user's experience that make your site experience more efficient and may not function properly.
              </p>

              <h1>Third-party disclosure</h1>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information
                unless we provide users with advance notice. This does not include website hosting partners and other
                parties who assist us in operating our website, conducting our business, or serving our users, so long as
                those parties agree to keep this information confidential. We may also release information when it's
                release is appropriate to comply with the law, enforce our site policies, or protect ours or others'
                rights, property or safety.However, non-personally identifiable visitor information may be provided to
                other parties for marketing, advertising, or other uses.
              </p>
              <h3>Third-party links</h3>
              <p>
                Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do
                so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
                However, non-personally identifiable visitor information may be provided to other parties for marketing,
                advertising, or other uses.
              </p>
              <h3>Google</h3>
              <p>
                We use Google AdSense Advertising on our website.
                Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie
                enables it to serve ads to our users based on previous visits to our site and other sites on the Internet.
                Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy
                policy. <br/>
                <strong>We have implemented the following: </strong><br />
                We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics
                cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers
                together to compile data regarding user interactions with ad impressions and other ad service functions as
                they relate to our website. <br/>
                <strong>Opting out:</strong> <br />
                Users can set preferences for how Google advertises to you using the Google Ad Settings page.
                Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page or by using the
                Google Analytics Opt Out Browser add on.
              </p>

              <h1>California Online Privacy Protection Act</h1>
              <p>
                CalOPPA is the first state law in the nation to require commercial websites and online services to post a
                privacy policy. The law's reach stretches well beyond California to require any person or company in the
                United States (and conceivably the world) that operates websites collecting Personally Identifiable
                Information from California consumers to post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals or companies with whom it is being shared. See more
                here
                According to CalOPPA, we agree to the following: <br />
                Users can visit our site anonymously. Once this privacy policy is created, we will add a link to it on our
                home page or as a minimum, on the first significant page after entering our website. Our Privacy Policy
                link includes the word 'Privacy' and can easily be found on the page specified above.
              </p>

              <h1>Changes to this Privacy Policy</h1>
              <p>
                We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy Policy on this page.
              </p>
              <p>
                We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective and update the "Last updated" date at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
                are effective when they are posted on this page.
              </p>
              <h1>Do not track signals</h1>
              <h3>How does our site handle Do Not Track signals?</h3>
              <p>
                We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track
                (DNT) browser mechanism is in place.
              </p>
              <h3>Does our site allow third-party behavioral tracking?</h3>
              <p>It's also important to note that we do not allow third-party behavioral tracking.</p>

              <h1>Children's Privacy</h1>
              <h3>COPPA (Children Online Privacy Protection Act)</h3>
              <p>
                When it comes to the collection of personal information from children under the age of 13 years old, the
                Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission,
                United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of
                websites and online services must do to protect children's privacy and safety online.
              </p>
              <h3>Do we let third-parties, including ad networks or plug-ins collect PII from children under 13?</h3>
              <p>
                We do not specifically market to children under the age of 13 years old.
              </p>

              <h1>Fair Information Practices</h1>
              <p>
                The Fair Information Practices Principles form the backbone of privacy law in the United States and the
                concepts they include have played a significant role in the development of data protection laws around the
                globe. Understanding the Fair Information Practice Principles and how they should be implemented is
                critical to comply with the various privacy laws that protect personal information.In order to be in line
                with Fair Information Practices we will take the following responsive action, should a data breach occur:
                We will notify you via email within 7 business days.
              </p>
              <p>
                We also agree to the Individual Redress Principle which requires that individuals have the right to
                legally pursue enforceable rights against data collectors and processors who fail to adhere to the law.
                This principle requires not only that individuals have enforceable rights against data users, but also
                that individuals have recourse to courts or government agencies to investigate and/or prosecute
                non-compliance by data processors.
                CAN SPAM Act
                The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for
                commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells
                out tough penalties for violations.
              </p>
              <p>
                We collect your email address in order to send information, respond to inquiries, and/or other requests or
                questions.
                To be in accordance with CANSPAM, we agree to the following:

              </p>
              <ul>
                <li>
                  <p>Not use false or misleading subjects or email addresses.</p>
                </li>
                <li>
                  <p>Identify the message as an advertisement in some reasonable way.</p>
                </li>
                <li>
                  <p>Include the physical address of our business or site headquarters.</p>
                </li>
                <li>
                  <p>Monitor third-party email marketing services for compliance, if one is used.</p>
                </li>
                <li>
                  <p>Honor opt-out/unsubscribe requests quickly.</p>
                </li>
                <li>
                  <p>Allow users to unsubscribe by using the link at the bottom of each email.</p>
                </li>
              </ul>
              <h1>Contacting us</h1>
              <p>
                If at any time you would like to unsubscribe from receiving future emails, you can email us at
                info@firstflight.io and we will promptly remove you from ALL correspondence.
              </p>
              <p>
                If there are any questions regarding this privacy policy, you may contact us using the information below: <br/>
                <a href="https://firstflight.io/">https://firstflight.io/</a><br/>
                <a href="mailto:info@firstflight.io">info@firstflight.io</a>
              </p>

            </Text>
          </ContentWithPaddingXl>
        </Container>
        <Footer />
      </AnimationRevealPage>
    </StyledDiv>

  );
};
