import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import AnimationRevealSection from "helpers/AnimationRevealSection";
import WhyChooseUs from "components/features/TwoColWithTwoFeaturesAndButtons";
import HowWeDoIt from "components/features/TwoColWithSteps";
import Roles from "components/features/ThreeColWithSideImage";
import Footer from "components/footers/MiniCenteredFooter";
import GetStarted from "components/cta/GetStarted";
import JoinNetwork from "components/features/TwoColWithButtonWithPrimaryBackground";

import { ReactComponent as UsersIcon } from "feather-icons/dist/icons/users.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as AwardIcon } from "feather-icons/dist/icons/award.svg";
import HowWeDoItImage from "images/undraw_shared_goals_3d12.svg";
import WhyChooseUsImage from "images/undraw_code_thinking_1jeh.svg";
import JoinNetworkImage from "images/undraw_connected_world_wuay.svg";
import ReactIcon from "images/react-brands.svg";
import DotNetCoreIcon from "images/dot-net-core.svg";
import AngularIcon from "images/angular-brands.svg";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => {
  const HighlightedText = tw.span`text-red-500`;
  return (
    <StyledDiv className="App">
      <Header roundedHeaderButton={true} />
      <AnimationRevealSection>
        <WhyChooseUs
          imageSrc={WhyChooseUsImage}
          heading={
            <>
              WHY <HighlightedText>CHOOSE US</HighlightedText>?
            </>
          }
          hideButton={false}
          primaryButtonText={"Register"}
          primaryButtonUrl="https://forms.gle/wQDzLieT58Lxk5EV7"
          description={
            "Learn in-demand tech skills through carefully curated training plans, project based learning and continuous evaluation system."
          }
          features={[
            {
              Icon: MoneyIcon,
              title: "No Tuition Until You're Hired",
              description:
                "You’ll only pay tuition after you start earning. Monthly payments are based on what you earn.",
              iconContainerCss: tw`bg-teal-300 text-teal-800`,
            },
            {
              Icon: AwardIcon,
              title: "We Help You Find A Job",
              description:
                "Work with our experts to source, interview, and get a job.",
              iconContainerCss: tw`bg-indigo-300 text-indigo-800`,
            },
            {
              Icon: UsersIcon,
              title: "Join Our Network",
              description:
                "Build network and connections with the industry leaders.",
              iconContainerCss: tw`bg-red-300 text-red-800`,
            },
          ]}
        />
         <JoinNetwork
          subheading={""}
          heading={"Join Our Network"}
          description={"Build network and connections with the industry leaders."}
          imageSrc={JoinNetworkImage}
          buttonHidden={true}
        />
        <Roles
          heading={"ROLES WE OFFER"}
          subheading={"Roles"}
          description={""}
          cards={[
            {
              imageSrc: ReactIcon,
              title: "MERN Stack Developer",
              description:
                "Carefully curated training plan with current industry standards in mind.",
            },
            {
              imageSrc: AngularIcon,
              title: "MEAN Stack Developer",
              description:
                "Carefully curated training plan with current industry standards in mind.",
            },
            {
              imageSrc: DotNetCoreIcon,
              title: ".Net Developer",
              description:
                "Carefully curated training plan with current industry standards in mind.",
            },
            //   { imageSrc: ReliableIconImage, title: "Reliable" },
            //   { imageSrc: FastIconImage, title: "Fast" },
            //   { imageSrc: SimpleIconImage, title: "Easy" },
          ]}
        />
        <GetStarted
          isExternal={false}
          primaryLinkText={"Contact Us"}
          primaryLinkUrl={"/contact-us"}
          secondaryLinkUrl={"/contact-us"}
          hideSecondaryButton={true}
          pushDownFooter={false}
          text={"We are the bridge to where you want to be!"}
        />
        <HowWeDoIt
          imageSrc={HowWeDoItImage}
          heading={"How we do it?"}
          subheading={"Process"}
          steps={[
            {
              heading: "Screening",
              description:
                "Candidates are screened through a proven and standardized test system.",
            },
            {
              heading: "Training",
              description:
                "Training Plans are carefully curated by industry leaders based on job roles.",
            },
            {
              heading: "Experience",
              description:
                "Candidates get exposure of a real world project during their course.",
            },
            {
              heading: "Evaluation",
              description:
                "Candidates go through various structured tests and mock interviews.",
            },
            {
              heading: "Placement Support",
              description:
                "We are always ready to help.",
            },
          ]}
        />
      </AnimationRevealSection>
      <Footer/>
    </StyledDiv>
  );
};
