import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import data from "../courseData";
import Header from "components/headers/light.js";
import AnimationRevealSection from "helpers/AnimationRevealSection.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButtonWithPrimaryBackground.js";
import FAQ from "components/faqs/SingleCol";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import ServerIcon from "images/server-icon.svg";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight text-center`;
export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-red-500`;
  const SmallText = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 text-gray-100`;
  return (
    <StyledDiv className="App">
      <Header roundedHeaderButton={true} />
      <AnimationRevealSection>
      <Heading>
          React Course Outline
      </Heading>
        <Footer />
      </AnimationRevealSection>
    </StyledDiv>
  );
};
